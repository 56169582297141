.generic-covid-testing-page {
  width: 100%;
  height: 100vh;
  font-family: 'RobotoRegular', sans-serif;
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.white-color {
  color: #fff;
}

.inner-content {
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.title-content-wrapper {
  width: 100%;
  max-width: 2400px;
  margin: 0 auto;
  background: url('../../images/hero-desktop.jpg') no-repeat center center;
  background-size: cover;
}

.title-content-background-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
}

.title-content-text {
  position: relative;
  width: 100%;
  height: 721px;
  z-index: 3;
}

.healthHeroLogo-wrapper {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 147px;
}

.title-content-text .inner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.font-title {
  font-family: 'RobotoBold', sans-serif;
}
.font-subtitle {
  font-family: 'RobotoRegular', sans-serif;
  font-weight: bold;
  letter-spacing: 0.05em;
}

.title-content-text-main-title {
  font-size: 70px;
  line-height: 70px;
  margin-bottom: 56px;
  max-width: 500px;
  color: #8cdbc9;
}

.title-content-text-main-subtitle {
  font-size: 20px;
  line-height: 30px;
  color: #fff;
}

.options-content-wrapper {
  padding: 75px 0;
  background-color: #475a79;
  color: #fff;
  max-width: 2400px;
  margin: 0 auto;
}

.options-title {
  font-size: 20px;
  line-height: 30px;
  text-align: left;
}

.options-items {
  padding: 75px 0;
}

.options-content-list-item {
  max-width: 225px;
  text-align: center;
}

.options-content-list-item.results-online {
  max-width: 216px;
}

.options-content-list-item-image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 166px;
  margin-bottom: 20px;
}

.options-content-list-item-image {
  margin-bottom: 15px;
}

.options-content-list-item-title {
  min-height: 54px;
  font-size: 24px;
  margin-bottom: 18px;
  letter-spacing: 0;
  line-height: 30px;
  color: #8cdbc9;
}

.options-content-list-item-subtitle {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.options-content-list {
  justify-content: space-between;
}

.options-coupon-code-title {
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 30px;
  font-family: 'RobotoRegular', sans-serif;
  font-weight: bold;
}

.options-coupon-code-links-first {
  display: flex;
  justify-content: space-between;
}

.options-coupon-code-link {
  padding: 8px 20px;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  width: 258px;
  text-decoration: none;
  text-align: center;
  background-color: #f4616b;
  color: #fff;
  font-family: 'RobotoRegular', sans-serif;
  font-weight: bold;
}

.transparent-link {
  background-color: transparent;
  border: 1px solid #fff;
  box-sizing: border-box;
}

.options-coupon-code-links-wrapper {
  margin-bottom: 20px;
  width: 70%;
}

.options-coupon-code-link-subtitle {
  color: #fff;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 13px;
  margin-top: 40px;
  text-align: center;
}

.options-coupon-code-terms-link {
  color: #fff;
  font-family: 'RobotoRegular', sans-serif;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
}

.how-it-works-content-wrapper {
  padding: 80px 0 100px;
  max-width: 2400px;
  margin: 0 auto;
  background-color: #f4616b;
}

.how-it-works-titles {
  text-align: left;
  letter-spacing: 0;
  padding-bottom: 40px;
}

.how-it-works-title {
  font-size: 70px;
  line-height: 70px;
  color: #ffffff;
  margin-bottom: 22px;
}

.how-it-works-title-color {
  color: #1c2f45;
}

.how-it-works-subtitle {
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  letter-spacing: 0;
}

.how-it-works-items {
  margin-bottom: 71px;
  margin-top: 40px;
}

.how-it-works-list {
  justify-content: space-between;
}

.how-it-works-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 154px;
}

.how-it-works-item-img-wrapper {
  position: relative;
  margin-bottom: 40px;
}

.how-it-works-item-title {
  margin-bottom: 21px;
  font-size: 28px;
  letter-spacing: 0;
  line-height: 36px;
  color: #ffffff;
}

.how-it-works-item-subtitle {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  color: #1c2f45;
}

.how-it-works-links-wrapper {
  width: 100%;
  text-align: left;
}

.how-it-works-link {
  width: 258px;
  padding: 12px;
  margin-bottom: 17px;
  border: 2px solid #fff;
  background-color: transparent;
  color: #fff;
  text-decoration: none;
  text-align: center;
}

.how-it-works-link-subtitle {
  color: #fff;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 14px;
}

@media (max-width: 1024px) {
  .inner-content {
    max-width: 660px;
  }

  .options-content-wrapper {
    padding: 75px 0 100px;
  }

  .title-content-wrapper {
    background: url('../../images/hero-tablet.jpg') no-repeat right center;
    background-size: cover;
  }

  .options-content-list {
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 550px;
    margin: 0 auto;
  }

  .title-content-text-main-title {
    font-size: 60px;
    line-height: 60px;
    margin-bottom: 88px;
  }

  .title-content-text-main-subtitle {
    font-size: 18px;
    line-height: 22px;
  }

  .options-content-list-item:not(:last-of-type) {
    margin-bottom: 80px;
  }

  .options-coupon-code-links-wrapper {
    width: 90%;
    margin-bottom: 0;
    text-align: center;
  }

  .options-coupon-code-links {
    margin-bottom: 20px;
  }

  .options-coupon-code-link-subtitle {
    font-size: 10px;
    line-height: 14px;
  }

  .how-it-works-list {
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
  }

  .how-it-works-item {
    margin-right: 42px;
  }

  .how-it-works-item:last-child {
    margin-right: 0;
  }

  .how-it-works-item:nth-child(1) {
    margin-bottom: 60px;
  }

  .how-it-works-item:nth-child(3) {
    margin-right: 0;
  }

  .options-coupon-code-title {
    max-width: 560px;
    text-align: center;
  }

  .how-it-works-title {
    font-size: 60px;
    line-height: 60px;
  }

  .how-it-works-subtitle {
    font-size: 18px;
    line-height: 24px;
  }

  .how-it-works-links-wrapper {
    text-align: center;
    align-items: center;
  }

  .healthHeroLogo-wrapper {
    width: 147px;
  }
}

@media (max-width: 767px) {
  .inner-content {
    max-width: 300px;
  }

  .title-content-wrapper {
    background: url('../../images/hero-mobile.jpg') no-repeat center center;
    background-size: cover;
  }

  .title-content-background-image {
    height: 365px;
    width: 713px;
  }

  .title-content-text {
    height: auto;
  }

  .title-content-text-main-title {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 38px;
    margin-top: 40px;
  }

  .title-content-text-main-subtitle {
    font-size: 17px;
    line-height: 23px;
  }

  .options-coupon-code-links {
    flex-direction: column;
  }

  .title-content-wrapper {
    height: 448px;
  }

  .title-content-background-wrapper {
    height: 100%;
  }

  .title-content-text-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 240px;
  }

  .options-coupon-code-link {
    margin-bottom: 10px;
  }

  .options-title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 60px;
  }

  .options-items {
    order: 2;
  }

  .options-coupon-code-wrapper {
    order: 1;
  }

  .options-coupon-code-title {
    text-align: left;
    font-size: 37px;
    line-height: 40px;
    margin-bottom: 40px;
    font-family: 'RobotoBold', sans-serif;
  }

  .options-coupon-code-links-wrapper {
    width: 100%;
    margin-bottom: 0;
  }

  .options-coupon-code-terms-link {
    font-size: 10px;
    line-height: 12px;
  }

  .options-content-list-item-title {
    max-width: 200px;
  }

  .options-content-list-item {
    align-items: center;
  }

  .options-content-list-item:not(:nth-last-child()) {
    margin-bottom: 60px;
  }

  .how-it-works-item {
    margin-right: 0px;
    margin-bottom: 60px;
    max-width: 100%;
  }

  .how-it-works-link-subtitle {
    font-size: 10px;
    line-height: 12px;
  }

  .options-coupon-code-links-first {
    flex-direction: column;
    align-items: center;
  }

  .options-coupon-code-link-subtitle {
    margin-top: 20px;
  }

  .options-coupon-code-terms-link {
    font-size: 16px;
    line-height: 21px;
  }

  .options-content-list {
    justify-content: center;
  }

  .how-it-works-title {
    font-size: 40px;
    line-height: 40px;
  }

  .healthHeroLogo-wrapper {
    width: 132px;
  }

  .subtitle-color {
    color: #8cdbc9;
  }
}
