@font-face {
  font-family: RobotoRegular;
  src: url(../fonts/Roboto/Roboto-Regular.ttf) format('ttf'),
    url(../fonts/Roboto/Roboto-Regular.woff) format('woff'),
    url(../fonts/Roboto/Roboto-Regular.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: RobotoBold;
  src: url(../fonts/Roboto/Roboto-Bold.ttf) format('ttf'),
    url(../fonts/Roboto/Roboto-Bold.woff) format('woff'),
    url(../fonts/Roboto/Roboto-Bold.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}
